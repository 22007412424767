import { Row, Col, Container, Tooltip, OverlayTrigger } from 'react-bootstrap';
import React, { useState } from 'react';
import Loader from '../../../../Components/Loader';
import PropTypes from 'prop-types';

export const useVideosState = (initialVideos) => {
  const [videos, setVideos] = useState(initialVideos);

  const updateVideoState = (id, newState) => {
    setVideos((prev) => ({
      ...prev,
      [id]: {
        ...prev[id],
        ...newState,
      },
    }));
  };

  const setVideoError = (id, message) => {
    updateVideoState(id, {
      status: 'error',
      error: message || 'Unknown error',
    });
  };

  const setVideoSuccess = (id, extra = null) => {
    updateVideoState(id, {
      status: 'success',
      error: null,
      isSelected: false,
      ...(extra || {}),
    });
  };

  const setVideoLoading = (id) => {
    updateVideoState(id, {
      status: 'loading',
    });
  };

  return { videos, updateVideoState, setVideoLoading, setVideoError, setVideoSuccess };
};

const VideoSelector = ({ videos, onSelect }) => {
  if (!videos?.length) return 'No videos found.';

  const styles = `
    .videoContainer {
      border: 1px solid transparent;
      border-radius: 10px;
      padding: 10px;
      font-size: 12px;
    }
    .videoContainer:hover {
      background:rgba(255, 255, 255, 0.13);
    }
    .videoContainer.success {
      background:rgba(145, 255, 202, 0.5);
    }
    .videoContainer.error, .videoContainer.selected.error {
      background:rgba(255, 145, 145, 0.5);
    }
    .videoContainer.selected {
      border: 1px solid lightgray;
      background:rgba(255, 255, 255, 0.27);
    }
  `;

  return (
    <Container>
      <style>{styles}</style>
      <Row className="gx-3 gy-3" style={{ height: '400px', overflowY: 'scroll' }}>
        {videos.map((video) => (
          <Col xs={3} className="position-relative" key={video.id}>
            <OverlayTrigger
              show={video.error ? undefined : false}
              placement="bottom"
              overlay={<Tooltip>Error: {video.error}</Tooltip>}
            >
              <div className={`videoContainer ${video.status} ${video.isSelected ? 'selected' : ''}`}>
                <video
                  id={`video-${video.id}`}
                  controls
                  preload="metadata"
                  className="rounded"
                  src={video.file}
                  style={{
                    opacity: video.status === 'loading' ? 0.4 : 1,
                    height: '200px',
                    width: '100%',
                  }}
                />
                <label
                  className="d-flex align-items-center justify-content-center w-full"
                  role={video.status === 'success' ? '' : 'button'}
                >
                  {video.status !== 'success' && (
                    <input
                      type="checkbox"
                      className="me-2"
                      checked={video.isSelected}
                      onChange={() => {
                        onSelect(video.id, { isSelected: !video.isSelected });
                      }}
                    />
                  )}
                  <span className="text-nowrap overflow-hidden text-center">
                    {video.error && '⚠'} {video.type}: {video.name}
                  </span>
                </label>
                {video.status === 'loading' && (
                  <div className="position-absolute top-50 start-50 translate-middle">
                    <Loader size={50} color="#3F96C7" />
                  </div>
                )}
              </div>
            </OverlayTrigger>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

VideoSelector.propTypes = {
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      type: PropTypes.string,
      file: PropTypes.string,
      status: PropTypes.string,
      error: PropTypes.string,
      isSelected: PropTypes.bool,
    }),
  ).isRequired,
  onSelect: PropTypes.func,
};

export default VideoSelector;

import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Alert } from 'react-bootstrap';
import {
  useUpdateFacebookAppIDMutation,
  useUpdateDraftGameFacebookAppIDMutation,
} from '../../../../../data/api/studioApi';
import { useGetApplicationMutation } from '../../../../../data/api/fbAdsApi';
import Loader from '../../../../../Components/Loader';
import { confirmAlert } from '../../../../../Components/ConfirmModal';

const FbAppModal = ({ data, element, variant = 'primary' }) => {
  const [showModal, setShowModal] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [fbAppId, setFbAppId] = useState(data.fbAppId || '');
  const [updateFacebookAppID, { isLoading: isUpdatingFbAppID }] = useUpdateFacebookAppIDMutation();
  const [updateDraftGameFacebookAppID, { isLoading: isUpdatingDraftGameFbAppID }] =
    useUpdateDraftGameFacebookAppIDMutation();
  const isLoading = isUpdatingFbAppID || isUpdatingDraftGameFbAppID;
  const [fetchFacebookApp, { data: facebookApp, isLoading: isLoadingFacebookApp, error: fbError }] =
    useGetApplicationMutation();

  useEffect(() => {
    setIsConfirmed(false);
  }, [fbAppId]);

  const handleSaveFbApp = async (e) => {
    e.stopPropagation();
    if (!isConfirmed) {
      const resp = await fetchFacebookApp(fbAppId);
      if (resp.data) setIsConfirmed(true);
      return;
    }
    const updateFbAppIDFunc = data?.isDraft ? updateDraftGameFacebookAppID : updateFacebookAppID;
    updateFbAppIDFunc({
      fb_app_id: fbAppId,
      studio_id: data.studioId,
      bundle_id: data.bundleId,
      store: data.store === 'amazon' ? ['amazon'] : [],
    })
      .unwrap()
      .then(() => {
        setShowModal(false);
        confirmAlert({
          variant: 'success',
          title: 'Facebook App ID was successfully saved',
          confirmBtn: false,
          cancelText: 'Ok',
        }).catch(() => {});
      })
      .catch((err) => {
        confirmAlert({
          variant: 'danger',
          title: err?.data?.message || 'Failed to save Facebook App ID',
          confirmBtn: false,
          cancelText: 'Ok',
        }).catch(() => {});
      });
  };

  const handleSaveButtonClick = () => {
    setShowModal(true);
  };

  return (
    <>
      <Button
        variant={variant}
        onClick={handleSaveButtonClick}
        size="sm"
        disabled={isUpdatingFbAppID || isUpdatingDraftGameFbAppID}
      >
        {isLoading ? <Loader parentStyle="me-2" size={20} color="#3F96C7" /> : element}
      </Button>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Save Facebook App ID</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!isLoadingFacebookApp ? (
            <Form>
              <Form.Group controlId="facebookAppId">
                <Form.Label>Facebook App ID</Form.Label>
                <Form.Control
                  type="text"
                  value={fbAppId}
                  onChange={(e) => setFbAppId(e.target.value)}
                  placeholder="Enter Facebook App ID"
                />
              </Form.Group>
              {fbError && (
                <Alert variant={'danger'} className="mt-3 mb-0">
                  Application not found. Make sure you created a consumer application and switched to live mode.
                </Alert>
              )}
              {facebookApp && (
                <>
                  <Alert variant={'info'} className="mt-3">
                    <div>
                      <b>Name:</b> {facebookApp.name}
                    </div>
                    <div>
                      <b>Supported Platforms:</b> {facebookApp.supported_platforms.join(',')}
                    </div>
                    <div>
                      <b>Store URLs</b>
                      <ul className="mb-2">
                        {Object.entries(facebookApp.object_store_urls).map(([key, value]) => {
                          if (key === 'instant_game') return null;
                          return (
                            <li key={key}>
                              <a target="_blank" rel="noreferrer" href={value}>
                                {key}
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    <div>
                      <b>Authorized Ad Accounts</b>
                      <ul className="mb-0">
                        {facebookApp.authorized_adaccounts.map((acc) => {
                          return (
                            <li key={acc.id}>
                              {acc.name} ({acc.id})
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </Alert>
                  Please save if information above is correct.
                </>
              )}
            </Form>
          ) : (
            <div className="d-flex justify-content-center">
              <Loader parentStyle="me-2" size={150} color="#3F96C7" />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSaveFbApp} disabled={!fbAppId || isLoading}>
            {isLoading ? 'Loading...' : isConfirmed ? 'Save' : 'Confirm'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FbAppModal;

import { Button, Modal } from 'react-bootstrap';
import React, { useState } from 'react';
import { useCreateAdMutation, useCreateCreativeMutation, useGetVideoMutation } from '../../../../../data/api/fbAdsApi';
import VideoSelector, { useVideosState } from '../VideoSelector';

function ProcessAdCreationModal({ state, setState, adset, handleClose, fetchedVideos }) {
  const { videos, updateVideoState, setVideoLoading, setVideoError, setVideoSuccess } = useVideosState(
    Object.entries(state.videos).reduce((acc, [id, x]) => {
      if (!x.facebookID) return acc;
      acc[id] = { id, facebookID: x.facebookID };
      return acc;
    }, {}),
  );
  const [processedCount, setProcessedCount] = useState(0);
  const [createCreative] = useCreateCreativeMutation();
  const [createAds] = useCreateAdMutation();
  const [getVideo] = useGetVideoMutation();

  const createFacebookAd = async (video) => {
    setVideoLoading(video.id);

    // get video
    let fbVideo = await getVideo(video.facebookID)
      .unwrap()
      .then((resp) => {
        if (resp.error) throw resp.error;
        return resp[0];
      })
      .catch((er) => {
        setVideoError(er?.error_user_title + ' ' + er?.error_data);
      });
    if (!fbVideo) return;

    // create creative
    const fbCreative = await createCreative({
      adset: adset?.id,
      accountId: state.client?.id,
      data: {
        name: fbVideo.name || fbVideo.title,
        object_story_spec: {
          page_id: state.pageId,
          video_data: {
            call_to_action: {
              type: 'PLAY_GAME',
              value: { link: adset?.promoted_object?.object_store_url },
            },
            image_url: fbVideo.picture || fbVideo.icon,
            video_id: fbVideo.id,
          },
        },
      },
    })
      .unwrap()
      .then((resp) => {
        if (resp.error) throw resp.error;
        return resp;
      })
      .catch((er) => {
        setVideoError(video.id, er?.error_user_title + ' ' + er?.error_user_msg);
      });
    if (!fbCreative) return;

    // create ad
    const fbAd = await createAds({
      adset: adset.id,
      accountId: state.client.id,
      data: {
        name: fbVideo.name || fbVideo.title,
        adset_id: adset.id,
        creative: { creative_id: fbCreative.id },
        status: 'ACTIVE',
      },
    })
      .unwrap()
      .then((resp) => {
        if (resp.error) throw resp.error;
        setVideoSuccess(video.id);
        return resp;
      })
      .catch((er) => {
        setVideoError(video.id, er?.error_user_title + ' ' + er?.error_user_msg);
      });

    if (!fbAd) return;

    setState({
      ads: [
        ...(state.ads || []),
        {
          adsetId: adset.id,
          videoId: video.id,
          adId: fbAd.id,
          accountId: state.client.id,
        },
      ],
      hasChanged: true,
    });

    return fbAd;
  };

  const createAdsOnFacebook = async () => {
    const videosToProcess = fetchedVideos
      .map((x) => ({ ...x, ...(videos[x.id] || {}) }))
      .filter((video) => video.isSelected);
    setProcessedCount(videosToProcess.length);
    await Promise.allSettled(videosToProcess.map(createFacebookAd));
    setProcessedCount(0);
  };

  return (
    <Modal show size="lg" onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Ad Creation for {adset?.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <VideoSelector
          videos={fetchedVideos
            .map((x) => {
              const fbAd = state.ads?.find((ad) => ad.adsetId === adset.id && ad.videoId === x.id.toString());
              return {
                ...x,
                ...(videos[x.id] || {}),
                ...(fbAd ? { status: 'success', extra: `Ad ID: ${fbAd.adId}` } : {}),
              };
            })
            .filter((x) => x.facebookID)}
          onSelect={updateVideoState}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="dark"
          onClick={createAdsOnFacebook}
          disabled={processedCount || !Object.values(videos).find((x) => x.isSelected)}
        >
          {processedCount ? `Creating... (${processedCount})` : 'Create Ads'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ProcessAdCreationModal;

import { Button, Modal, Form } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import { useUploadVideosMutation } from '../../../../../data/api/fbAdsApi';
import Loader from '../../../../../Components/Loader';
import UploadIcon from '../../../../../Components/Icons/UploadIcon';
import VideoSelector, { useVideosState } from '../VideoSelector';

function FbVideosModal({ isFetching, fetchedVideos, gameVideos, client, setState, handleClose }) {
  const { videos, updateVideoState, setVideoLoading, setVideoError, setVideoSuccess } = useVideosState(gameVideos);
  const [uploadCount, setUploadCount] = useState(0);
  const [uploadVideos] = useUploadVideosMutation();
  const [selectedType, setSelectedType] = useState('');
  const uniqueTypes = [...new Set(fetchedVideos.map((x) => x.type))];

  useEffect(() => {
    setState({
      videos: videos,
      hasChanged: true,
    });
  }, [videos]);

  const handleFacebookUpload = async (video) => {
    setVideoLoading(video.id);
    return uploadVideos({ accountId: client.id, url: video.file, name: video.name })
      .unwrap()
      .then((result) => {
        if (result?.error) {
          setVideoError(video.id, result.error?.data?.message || result.error?.message);
        } else {
          setVideoSuccess(video.id, { facebookID: result.id, accountID: client.id });
        }
      })
      .catch((error) => {
        setVideoError(video.id, error.error);
      })
      .finally(() => {
        setUploadCount((prev) => prev - 1);
      });
  };

  const uploadVideosToFacebook = async () => {
    const videosToUpload = fetchedVideos.filter((video) => videos[video.id]?.isSelected);
    setUploadCount(videosToUpload.length);
    await Promise.allSettled(videosToUpload.map(handleFacebookUpload));
    setUploadCount(0);
  };

  return (
    <Modal show onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Game Videos</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-between align-items-center">
          <p>Upload Videos</p>
          <Form.Select
            aria-label="Filter by Type"
            onChange={(e) => setSelectedType(e.target.value)}
            className="mb-3 w-25"
          >
            <option value="">All Types</option>
            {uniqueTypes.map((type, index) => (
              <option value={type} key={index}>
                {type}
              </option>
            ))}
          </Form.Select>
        </div>
        {isFetching ? (
          <div className="d-flex justify-content-center align-items-center" style={{ minHeight: 200 }}>
            <Loader parentStyle="loader" size={50} color="#3F96C7" />
          </div>
        ) : (
          <VideoSelector
            videos={
              // filter and merge all videos with game related properties
              fetchedVideos
                .filter((video) => !selectedType || video.type === selectedType)
                .map((x) => ({ ...x, ...videos[x.id] }))
            }
            onSelect={updateVideoState}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="dark"
          onClick={uploadVideosToFacebook}
          disabled={uploadCount}
          className="d-flex align-items-center gap-2"
        >
          <UploadIcon size="17" color="#fff" />
          {uploadCount ? `Uploading... (${uploadCount})` : 'Upload Selected Videos'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default FbVideosModal;

import { Row, Col } from 'react-bootstrap';

const TargetingErrorMessage = ({ error }) => {
  return (
    <div className="alert alert-danger fs-7">
      {error?.message || 'Something went Wrong'}
      {error?.detail?.overlap && (
        <div className="mt-2">
          <b>Overlapped Criteria</b>
          <div>
            {error.detail.overlap.map((row) => {
              return (
                <Row>
                  <Col className="col-auto text-truncate text-capitalize text-end w-25">{row.name}</Col>
                  <Col className="col-auto text-truncate text-capitalize px-0 w-75">{row.vals.join(', ')}</Col>
                </Row>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default TargetingErrorMessage;

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CampaignList from './FbLists/CampaignList';
import FBCustomerClientList from './FbLists/FBCustomerClientList';
import { getGameById } from '../../../../data/slices/studio';
import {
  useGetFacebookSettingsQuery,
  useGetGameVideosQuery,
  useUpdateFacebookSettingsMutation,
} from '../../../../data/api/studioApi';
import Loader from '../../../../Components/Loader';
import { Button } from 'react-bootstrap';
import FbVideosModal from './FbModals/FbVideosModal';
import { useSetState } from '../../../../utils/customHooks';
import FbAdSets from './FbLists/FbAdSets';
import FbPagesList from './FbLists/FbPagesList';
import CreateAdsetModal from './FbModals/CreateAdsetModal';
import CreateCampaignModal from './FbModals/CreateCampaignModal';
import FbAppModal from './FbApp/FbAppModal';
import GameInfoCard from '../../../../Components/GameInfoCard';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function FbAds() {
  const { id } = useParams();
  const foundGame = useSelector(getGameById(id));
  const [modalType, setModalType] = useState(null);
  const [state, setState] = useSetState({
    adSets: [],
    appUrl: '',
    client: null,
    createAdSet: [],
    pageName: '',
    pageId: '',
    hasChanged: false,
    selectedCampaign: null,
    videos: {},
  });
  const { data: savedSettings, isLoading: isLoadingSettings } = useGetFacebookSettingsQuery(foundGame?.appId, {
    skip: !foundGame?.appId,
  });
  const { data: fetchedVideos } = useGetGameVideosQuery(
    { appId: foundGame?.appId, studioId: foundGame?.studioId },
    { skip: !foundGame?.appId },
  );

  const [updateFacebookSettings] = useUpdateFacebookSettingsMutation();

  useEffect(() => {
    const parsedValue = savedSettings?.value ? JSON.parse(savedSettings.value) : null;
    if (parsedValue) {
      setState({ ...parsedValue, hasChanged: false });
    }
  }, [savedSettings, setState]);

  const saveSettings = () => {
    updateFacebookSettings({
      appId: foundGame?.appId,
      settings: state,
    })
      .unwrap()
      .then(() => {
        console.log('Settings saved successfully');
      })
      .catch((error) => console.error('Failed to save settings:', error))
      .finally(() => setState({ hasChanged: false }));
  };

  useEffect(() => {
    if (state.hasChanged) {
      saveSettings();
    }
  }, [state.hasChanged]);

  if (isLoadingSettings) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ minHeight: 200 }}>
        <Loader parentStyle="loader" size={50} color="#3F96C7" />
      </div>
    );
  }

  return (
    <div className="d-flex flex-column gap-3">
      <div className="col-sm-12 col-md-6 col-xl-4">
        <GameInfoCard id={id} />
      </div>
      {!foundGame ? (
        <div className="d-flex justify-content-center w-100">
          <Loader parentStyle="loader" size={50} color={'#3F96C7'} />
        </div>
      ) : !foundGame?.fbAppId ? (
        <FbAppModal data={foundGame} element={'Create Facebook App ID'} />
      ) : (
        <div className="d-flex flex-column gap-3">
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center gap-3">
              <strong className="text-end align-items-center" style={{ minWidth: 200 }}>
                Facebook App ID:
              </strong>
              {foundGame.fbAppId}
            </div>
          </div>

          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center gap-3">
              <strong className="text-end align-items-center" style={{ minWidth: 200 }}>
                Facebook Page:
              </strong>
              <FbPagesList
                value={state.pageId}
                onChange={({ pageId, pageName }) => {
                  setState({ pageId, pageName, hasChanged: true });
                }}
              />
            </div>
          </div>

          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center gap-3">
              <strong className="text-end align-items-center" style={{ minWidth: 200 }}>
                Ad account:
              </strong>
              <FBCustomerClientList
                value={state.client?.id}
                onSelect={(client) => {
                  setState({ client, selectedCampaign: null, hasChanged: true });
                }}
              />
              {fetchedVideos && state?.client?.name && (
                <Button size="sm" onClick={() => setModalType('videos')} className="text-nowrap">
                  Upload videos to FB
                </Button>
              )}
            </div>
          </div>

          {state.client && state.pageId && (
            <>
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center gap-3">
                  <strong className="text-end align-items-center" style={{ minWidth: 200 }}>
                    Campaign:
                  </strong>
                  <CampaignList state={state} setState={setState} />
                  <Button size="sm" onClick={() => setModalType('campaign')}>
                    or Add New
                  </Button>
                </div>
              </div>

              {state.selectedCampaign && (
                <Container>
                  <Row className="p-2">
                    <Col className="d-flex align-items-center">
                      <h4 className="m-0">Adsets</h4>
                      <Button size="sm" onClick={() => setModalType('adSet')} className="ms-3">
                        Add AdSet
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <FbAdSets fetchedVideos={fetchedVideos} state={state} setState={setState} />
                  </Row>
                </Container>
              )}
            </>
          )}
        </div>
      )}
      {modalType === 'videos' && (
        <FbVideosModal
          handleClose={() => setModalType(null)}
          client={state.client || state.currentSettings?.client}
          setState={setState}
          fetchedVideos={fetchedVideos}
          gameVideos={state.videos}
        />
      )}
      {modalType === 'adSet' && (
        <CreateAdsetModal
          closeModal={() => setModalType(null)}
          campaign={state.selectedCampaign}
          client={state.client}
          onSuccess={(adSet) => {
            setState({
              createAdSet: [...(state.createAdSet || []), adSet],
              hasChanged: true,
            });
          }}
        />
      )}
      {modalType === 'campaign' && (
        <CreateCampaignModal
          clientId={state.client?.id}
          closeModal={() => setModalType(null)}
          onSuccess={(campaign) =>
            setState({
              selectedCampaign: campaign,
              hasChanged: true,
            })
          }
        />
      )}
    </div>
  );
}

export default FbAds;

import React, { useState } from 'react';
import { useListAdSetsQuery } from '../../../../../data/api/fbAdsApi';
import Loader from '../../../../../Components/Loader';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import ProcessAdCreationModal from '../FbModals/ProcessAdCreationModal';

const FbAdSets = ({ state, setState, fetchedVideos }) => {
  let campaign = state?.selectedCampaign;
  const [selectedAdSet, setSelectedAdSet] = useState(null);
  const { data: adSets, isFetching } = useListAdSetsQuery({ id: campaign?.id }, { skip: !campaign?.id });
  const handleOpenModal = (adSet) => setSelectedAdSet(adSet);
  const handleCloseModal = () => setSelectedAdSet(null);

  const formatMoney = (cents) => {
    return (cents / 100).toLocaleString('en-US', { style: 'currency', currency: state.client?.currency || 'USD' });
  };

  if (isFetching) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ minHeight: 200 }}>
        <Loader parentStyle="loader" size={50} color={'#3F96C7'} />
      </div>
    );
  }

  if (!adSets?.length) {
    return <h6>No active adsets found.</h6>;
  }

  return (
    <>
      <div className="table-responsive">
        <table className="table table-hover align-middle rounded overflow-hidden bg-white tap-n-table text-center">
          <thead>
            <tr>
              <th className="text-start">Name</th>
              <th>Status</th>
              <th>Start Time</th>
              <th>Daily Budget</th>
              <th>Budget Remaining</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {adSets.map((item) => (
              <tr className="cursor-pointer" key={item.id}>
                <td className="text-start">{item.name}</td>
                <td>{item.status}</td>
                <td>{moment(item.start_time).format('ll')}</td>
                <td>{formatMoney(item.daily_budget)}</td>
                <td>{formatMoney(item.budget_remaining)}</td>
                <td>
                  <Button size="sm" onClick={() => handleOpenModal(item)}>
                    Create Ads
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {selectedAdSet && (
        <ProcessAdCreationModal
          state={state}
          adset={selectedAdSet}
          setState={setState}
          handleClose={handleCloseModal}
          fetchedVideos={fetchedVideos}
        />
      )}
    </>
  );
};

export default FbAdSets;

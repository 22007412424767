import { useEffect, useState } from 'react';
import { useSetState } from '../../../utils/customHooks';
import { useChangeABTestGroupMutation } from '../../../data/api/abTestsApi';
import { Button, Col, Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { abTestCountries } from '../../../constants/abTestCountries';
import Params from '../CreateAbTestModal/_Components/Params';
import Loader from '../../../Components/Loader';
import SearchableDropdown from '../../../Components/SearchableDropdown';
import cohortParamsSchema from '../CreateAbTestModal/_validationSchemas/cohortParamsSchema';
import { formatJoiErrorsArray } from '../../../utils/validation';
import { useSelector } from 'react-redux';
import { getGameByBundleId } from '../../../data/slices/abTests';

function UpdateConfigModal({ abTest, show, onClose }) {
  const [showModal, setShowModal] = useState(show || false);
  const foundGame = useSelector((state) => getGameByBundleId(state, abTest.bundleId));
  /** State */
  const [state, setState] = useSetState({
    studioId: '',
    deviceId: '',
    country: [],
    paramsDeleted: [],
    error: '',
  });
  const [paramsArray, setParamsArray] = useState([]);
  useEffect(() => {
    if (abTest && showModal) {
      setParamsArray(abTest?.params.length ? JSON.parse(JSON.stringify(abTest?.params)) : []);

      setState({
        deviceId: abTest?.deviceId,
        country: abTest?.country?.split(',').filter(Boolean),
      });
    }
  }, [abTest, showModal]);
  const handleClose = () => {
    if (onClose) onClose();
    else setShowModal(false);
  };

  const handleDeleteParam = (index) => {
    let newArray = [...paramsArray];
    if (newArray.length > 1) {
      let deletedItem = newArray.splice(index, 1)[0];
      setParamsArray(newArray);
      setState({
        paramsDeleted: [...state.paramsDeleted, deletedItem],
      });
    }
  };

  const handleUndo = () => {
    const lastDeletedItem = state.paramsDeleted[state.paramsDeleted.length - 1];
    setParamsArray((newArray) => [...newArray, lastDeletedItem]);
    setState({
      paramsDeleted: state.paramsDeleted.slice(0, -1),
    });
  };

  const handleParamTextChange = (text, field, index) => {
    setState({ error: '' });
    setParamsArray((old) =>
      old.map((el, ind) => (ind === index ? { ...el, [field]: text?.trim(), modified: true } : el)),
    );
  };

  const handleAddParam = () => {
    const { error } = cohortParamsSchema.validate(paramsArray);
    if (error) {
      setState({ error: formatJoiErrorsArray(error.details) });
      return false;
    }
    setParamsArray((oldArray) => [...oldArray, { name: '', value: '' }]);
  };

  /** CHANGE CONFIG FUNCTIONS */
  const [changeGroupStatus, { isLoading }] = useChangeABTestGroupMutation();
  const handleUpdate = () => {
    const { error } = cohortParamsSchema.validate(paramsArray);
    if (error) {
      setState({ error: formatJoiErrorsArray(error.details) });
      return false;
    }
    const modifiedParams = paramsArray
      .filter((param) => param.modified || !param.id)
      .map((value) => {
        return { name: value.name, value: value.value };
      });

    let deleteParams = state.paramsDeleted.map((value) => value.id).filter(Boolean);

    changeGroupStatus({
      studioId: foundGame?.studioId,
      id: abTest.id,
      bundleId: abTest.bundleId,
      name: abTest.name, // name is needed for email sending
      type: 'CONFIG',
      params: modifiedParams,
      paramsDeleted: deleteParams,
      deviceId: state.deviceId,
      country: state.country?.filter(Boolean)?.join(','),
    })
      .unwrap()
      .then(() => {
        handleClose();
      })
      .catch((error) => {
        setState({ error: error?.data?.message || 'something went wrong' });
      });
  };
  const handleDeviceChange = (e) => {
    let text = e.target.value;
    if (text.match(/([0-9a-fA-F-])$/g) || text === '') {
      return setState({ deviceId: text.split(' ').join('') });
    } else {
      return null;
    }
  };

  return (
    <>
      <Modal
        centered
        dialogClassName="modal-lg"
        show={showModal}
        onHide={handleClose}
        backdrop="static"
        id="update-config-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Cohort Config</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ minHeight: 400 }}>
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center" style={{ minHeight: 200 }}>
              <Loader parentStyle="loader" size={50} color={'#3F96C7'} />
            </div>
          ) : (
            abTest && (
              <>
                <div className="row mb-4 g-3">
                  <Form.Group className="position-relative cursor-pointer" as={Col} xs={12} controlId="deviceId">
                    <Form.Label className="fs-7">
                      Device Id <span className="text-muted fs-7"> (testing on one device)</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      className=" cursor-pointer"
                      aria-describedby=""
                      placeholder="Type Device Id"
                      onChange={handleDeviceChange}
                      value={state.deviceId}
                      name="device-id"
                    />
                  </Form.Group>
                  <Form.Group className="position-relative" as={Col} xs={12} controlId="country">
                    <Form.Label className="fs-7 ">Country</Form.Label>
                    <SearchableDropdown
                      multiple
                      collapseGroup
                      onSelect={(data) => setState({ country: data })}
                      selected={state.country}
                      options={abTestCountries}
                      disableSearch
                    />
                  </Form.Group>
                </div>
                <div className="config-container position-relative">
                  <Params
                    errors={state.error}
                    paramsArray={paramsArray}
                    handleParamTextChange={handleParamTextChange}
                    handleAddParam={handleAddParam}
                    handleDeleteParam={handleDeleteParam}
                  />
                  {state.paramsDeleted.length > 0 && (
                    <Button
                      size="sm"
                      variant="outline-secondary"
                      className="position-absolute top-0 end-0"
                      onClick={handleUndo}
                    >
                      Undo
                    </Button>
                  )}
                </div>

                {state.error && typeof state.error === 'string' && (
                  <div className="alert alert-danger">{state.error || 'Validation failed'}</div>
                )}
              </>
            )
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Abort
          </Button>
          <Button variant="primary" onClick={handleUpdate} id="save-config">
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UpdateConfigModal;
